import React from "react";
import { translate } from "react-admin";

const MemberListTitle = ({ translate }) => {
  return (
    <span>
      {translate("organizationMembers.list.title")}
      {` "`}
      {localStorage.getItem("workingOrganizationName")}
      {`"`}
    </span>
  );
};

export default translate(MemberListTitle);
