// in src/users.js
import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EmailField,
  EditButton,
  ReferenceField,
  Edit,
  TextInput,
  BooleanInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  DisabledInput,
  Create,
  Filter,
  BooleanField,
  Show,
  TabbedShowLayout,
  Tab,
  ArrayField
} from "react-admin";

var constants = require("../moduleConstants");
const ADMINUI_URL = constants.ADMINUI_URL;

const setCurrentUser = ({ record }) => {
  localStorage.setItem("workingUserId", record.id);
  localStorage.setItem("workingUserName", record.name);
};

const ContactsViewLink = ({ record }) => {
  setCurrentUser({ record });
  var link = `${ADMINUI_URL}/#/contactsInUser`;
  return (
    <p>
      För att lägga till, ändra eller ta bort kontakter, klicka{" "}
      <a href={link}>här</a>
    </p>
  );
};

const UserTitle = ({ record }) => {
  return <span>Användare {record ? `"${record.name}"` : ""}</span>;
};

const UserFilter = props => (
  <Filter {...props}>
    <TextInput label="users.list.search" source="q" alwaysOn />
  </Filter>
);

export const UserList = props => (
  <List filters={<UserFilter />} {...props}>
    <Datagrid rowClick="show">
      <TextField source="name" />
      <EmailField source="emailAddress" sortBy="searchTerm1" />
      <TextField source="mobileNumber" sortBy="searchTerm2" />
      <TextField source="role" />
      <ReferenceField
        source="initialOrganizationId"
        reference="organization"
        linkType="show"
        allowEmpty
      >
        <TextField source="name" />
      </ReferenceField>
      <EditButton />
    </Datagrid>
  </List>
);

export const UserEdit = props => (
  <Edit title={<UserTitle />} {...props}>
    <SimpleForm>
      <DisabledInput source="id" />
      <TextInput source="firstName" />
      <TextInput source="lastName" />
      <TextInput source="emailAddress" />
      <TextInput source="mobileNumber" />
      <TextInput source="role" />
      <BooleanInput source="active" />
      <TextInput source="locale" />
    </SimpleForm>
  </Edit>
);

export const UserCreate = props => (
  <Create title="Skapa användare" {...props}>
    <SimpleForm>
      <TextInput source="firstName" />
      <TextInput source="lastName" />
      <TextInput source="emailAddress" />
      <TextInput source="mobileNumber" />
      <TextInput source="role" />
      <BooleanInput source="active" />
      <ReferenceInput source="initialOrganizationId" reference="organization">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput source="locale" />
    </SimpleForm>
  </Create>
);

export const UserShow = props => (
  <Show title={<UserTitle />} {...props}>
    <TabbedShowLayout>
      <Tab label="users.show.tabGeneral">
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="role" />
        <EmailField source="emailAddress" />
        <TextField source="mobileNumber" />
        <BooleanField source="active" />
        <ReferenceField
          source="initialOrganizationId"
          reference="organization"
          linkType="show"
          allowEmpty
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField source="locale" />
      </Tab>
      <Tab label="users.show.tabOrganizations">
        <ArrayField source="organizations">
          <Datagrid>
            <ReferenceField
              label="users.show.organization"
              reference="organization"
              source="id"
              linkType="show"
            >
              <TextField source="name" />
            </ReferenceField>
          </Datagrid>
        </ArrayField>
      </Tab>
      <Tab label="users.show.tabGroups">
        <ArrayField source="groups">
          <Datagrid>
            <ReferenceField
              label="users.show.group"
              source="groupId"
              reference="groups"
              linkType="show"
            >
              <TextField source="name" />
            </ReferenceField>
            <ReferenceField
              label="users.show.organization"
              source="organizationId"
              reference="organization"
              linkType="show"
            >
              <TextField source="name" />
            </ReferenceField>
            <TextField source="role" />
          </Datagrid>
        </ArrayField>
      </Tab>
      <Tab label="users.show.tabRelations">
        <ArrayField source="relations">
          <Datagrid>
            <ReferenceField
              label="users.show.name"
              source="organizationMemberId"
              reference="organizationMembers"
              linkType="show"
            >
              <TextField source="name" />
            </ReferenceField>
            <TextField
              source="organizationMemberAssociation"
              label="users.show.association"
            />
          </Datagrid>
        </ArrayField>
      </Tab>
      <Tab label="users.show.tabContacts">
        <ContactsViewLink />
      </Tab>
    </TabbedShowLayout>
  </Show>
);
