import React from "react";
import {
  Datagrid,
  TextField,
  ReferenceField,
  Show,
  Edit,
  TabbedShowLayout,
  Tab,
  DateField,
  ArrayField,
  BooleanField,
  SimpleForm,
  TextInput,
  BooleanInput,
  DateInput
} from "react-admin";

var constants = require("../moduleConstants");
const ADMINUI_URL = constants.ADMINUI_URL;

const setCurrentGroup = ({ record }) => {
  localStorage.setItem("workingPosId", record.id);
  localStorage.setItem("workingPosName", record.name);
};


const PosTitle = ({ record }) => {
  return <span>Kassa {record ? `"${record.name}"` : ""}</span>;
};

const redirect = (basePath, id, data) => {
  var orgId = localStorage.getItem("workingOrganizationId");
  return `/organization/${orgId}/show/3`;
}

export const PointOfSaleShow = props => (
  <Show title={<PosTitle />} {...props}>
    <TabbedShowLayout>
      <Tab label="groups.show.tabGeneral">
        <TextField source="id" />
        <TextField source="name" />
      </Tab>
      <Tab label="groups.show.tabUsers">
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export const PointOfSaleEdit = props => (
  <Edit title={<PosTitle />} {...props}>
    <SimpleForm redirect={redirect}>
        <TextField source="id" />
        <TextInput source="name" />
        <BooleanInput source="excludeFromBilling" />
        <BooleanInput source="active" />
        <BooleanInput source="allowComment" />
        <BooleanInput source="inheritPrivileges" />
        <DateInput source="startDate" />
        <DateInput source="endDate" />
    </SimpleForm>
  </Edit>
);