import React from "react";
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  EditButton,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  Filter,
  Show,
  TabbedShowLayout,
  Tab,
  DateField,
  DateInput,
  DeleteButton,
  ArrayField
} from "react-admin";

import MemberListTitle from "./ListTitles/MemberListTitle";

const MemberTitle = ({ record }) => {
  return (
    <span>
      Medlem {record ? `"${record.firstName} ${record.lastName}"` : ""}
    </span>
  );
};

const MemberFilter = props => (
  <Filter {...props}>
    <TextInput label="organizationMembers.list.search" source="q" alwaysOn />
  </Filter>
);

export const MemberList = props => (
  <List
    filters={<MemberFilter />}
    title={<MemberListTitle />}
    bulkActionButtons={false}
    {...props}
  >
    <Datagrid rowClick="show">
      <TextField source="firstName" sortable={false} />
      <TextField source="lastName" sortable={false} />
      <DateField source="dateOfBirth" sortable={false} />
      <EditButton />
      <DeleteButton />
    </Datagrid>
  </List>
);

export const MemberEdit = props => (
  <Edit title={<MemberTitle />} {...props}>
    <SimpleForm>
      <TextInput source="firstName" />
      <TextInput source="lastName" />
      <DateInput source="dateOfBirth" />
    </SimpleForm>
  </Edit>
);

export const MemberCreate = props => (
  <Create {...props}>
    <SimpleForm redirect="list">
      <TextInput source="firstName" />
      <TextInput source="lastName" />
      <DateInput source="dateOfBirth" />
    </SimpleForm>
  </Create>
);

export const MemberShow = props => (
  <Show title={<MemberTitle />} {...props}>
    <TabbedShowLayout>
      <Tab label="organizationMembers.show.tabGeneral">
        <TextField source="id" />
        <TextField source="name" />
        <DateField source="dateOfBirth" />
        <ReferenceField
          source="userId"
          reference="users"
          linkType="show"
          allowEmpty
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          source="organizationId"
          reference="organization"
          linkType="show"
        >
          <TextField source="name" />
        </ReferenceField>
      </Tab>
      <Tab label="organizationMembers.show.tabRelations">
        <ArrayField
          label="organizationMembers.show.tabRelations"
          source="relations"
        >
          <Datagrid>
            <ReferenceField
              label="organizationMembers.show.name"
              source="userId"
              reference="users"
              linkType="show"
            >
              <TextField source="name" />
            </ReferenceField>
            <TextField
              source="userAssociation"
              label="organizationMembers.show.association"
            />
          </Datagrid>
        </ArrayField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);
