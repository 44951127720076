import React from "react";
import {
  TextField,
  SimpleForm,
  Create,
  Edit,
  DateField,
  DateInput,
  ReferenceInput,
  SelectInput
} from "react-admin";
import { parse } from "query-string";

var constants = require("../moduleConstants");
const ADMINUI_URL = constants.ADMINUI_URL;
const API_URL = constants.API_URL;
const today = new Date();
const later = (new Date()).setMonth(today.getMonth()+12);


const validateAgreement = (values) => {
  const errors = {};
  if (!values.agreementId) {
      errors.agreementId = ['Agreement is required'];
  }  
  return errors
};


export const AgreementCreate = props => {
  const { organizationId: organizationId_string } = parse(props.location.search);
  const organizationId = organizationId_string;

  const redirect = organizationId ? `/organization/${organizationId}/show/5` : false;

   return (
  <Create {...props}>
    <SimpleForm 
        defaultValue={{ organizationId, startDate: today, endDate: later, signDate: today }}
        redirect={redirect}
        validate={validateAgreement}
      >
        <ReferenceInput source="agreementId" reference="agreement">
          <SelectInput optionText="name" />
        </ReferenceInput>
        <TextField source="id" />
        <TextField source="name" />
        <DateInput source="signDate" />
        <DateInput source="startDate" />
        <DateInput source="endDate" />
    </SimpleForm>
  </Create>
   );
};


export const AgreementEdit = props => {
  const { organizationId: organizationId_string } = parse(props.location.search);
  const organizationId = organizationId_string;

  const redirect = organizationId ? `/organization/${organizationId}/show/5` : false;

   return (
  <Edit {...props}>
    <SimpleForm 
        defaultValue={{ organizationId, startDate: today, endDate: later }}
        redirect={redirect}
      >
        <ReferenceInput source="agreementId" reference="agreement">
          <SelectInput optionText="name" />
        </ReferenceInput>
        <TextField source="id" />
        <TextField source="name" />
        <DateInput source="signDate" />
        <DateInput source="startDate" />
        <DateInput source="endDate" />
    </SimpleForm>
  </Edit>
   );
};
