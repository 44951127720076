import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import LocaleSwitcher from "../Components/LocaleSwitcher";

export default () => (
  <React.Fragment>
    <Card>
      <CardHeader title="TeamTools Administrationsverktyg - AdminUI" />
      <CardContent>
        Hej! Välkommen till TeamTools verktyg för administrering av
        TeamTools-plattformen. Klicka på någon av flikarna till vänster för att
        hantera föreningar och användare.
      </CardContent>
    </Card>
    <Card>
      <LocaleSwitcher />
    </Card>
  </React.Fragment>
);
