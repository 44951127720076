import React from "react";
import {
  Datagrid,
  TextField,
  ReferenceField,
  Show,
  TabbedShowLayout,
  Tab,
  DateField,
  ArrayField
} from "react-admin";

var constants = require("../moduleConstants");
const ADMINUI_URL = constants.ADMINUI_URL;

const setCurrentGroup = ({ record }) => {
  localStorage.setItem("workingGroupId", record.id);
  localStorage.setItem("workingGroupName", record.name);
};

const ContactsViewLink = ({ record }) => {
  setCurrentGroup({ record });
  var link = `${ADMINUI_URL}/#/contactsInGroup?filter=%7B%22organizationId%22%3A%22${
    record.id
  }%22%7D`;
  return (
    <p>
      För att lägga till, ändra eller ta bort kontakter, klicka{" "}
      <a href={link}>här</a>
    </p>
  );
};

const GroupTitle = ({ record }) => {
  return <span>Grupp {record ? `"${record.name}"` : ""}</span>;
};

export const GroupShow = props => (
  <Show title={<GroupTitle />} {...props}>
    <TabbedShowLayout>
      <Tab label="groups.show.tabGeneral">
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="description" />
        <ReferenceField
          source="parentId"
          reference="groups"
          linkType="show"
          allowEmpty
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          source="responsibleUserId"
          reference="users"
          linkType="show"
        >
          <TextField source="name" />
        </ReferenceField>
      </Tab>
      <Tab label="groups.show.tabUsers">
        <ArrayField source="members">
          <Datagrid>
            <ReferenceField
              label="users.show.name"
              source="userId"
              reference="users"
              linkType="show"
            >
              <TextField source="mobileNumber" />
            </ReferenceField>
            <ReferenceField
              label="users.show.name"
              source="userId"
              reference="users"
              linkType="show"
            >
              <TextField source="emailAddress" />
            </ReferenceField>
            <TextField source="role" />
          </Datagrid>
        </ArrayField>
      </Tab>
      <Tab label="groups.show.tabMembers">
        <ArrayField source="organizationMembers">
          <Datagrid>
            <ReferenceField
              label="users.show.name"
              source="id"
              reference="organizationMembers"
              linkType="show"
            >
              <TextField source="name" />
            </ReferenceField>
            <DateField source="dateOfBirth" />
          </Datagrid>
        </ArrayField>
      </Tab>
      <Tab label="groups.show.tabContacts">
        <ContactsViewLink />
      </Tab>
    </TabbedShowLayout>
  </Show>
);
