import React from "react";
import {
  Datagrid,
  TextField,
  ReferenceField,
  Show,
  TabbedShowLayout,
  Tab,
  DateField,
  ArrayField,
  List
} from "react-admin";

var constants = require("../moduleConstants");
const ADMINUI_URL = constants.ADMINUI_URL;

export const RolesList = props => (
    <List {...props}>
      <Datagrid rowClick="show">
        <TextField source="name" />      
      </Datagrid>
    </List>
  );


  const RoleTitle = ({ record }) => {
    return <span>{record ? `"${record.name}"` : ""}</span>;
  };

export const RoleShow = props => (
    <Show title={<RoleTitle />} {...props}>
            <TabbedShowLayout>
      <Tab label="events.show.tabGeneral">
          <TextField source="name" />
        <ArrayField source="privileges">
            <Datagrid>
                <TextField source="code" />      
            </Datagrid>
        </ArrayField>
      </Tab>
      </TabbedShowLayout>
    </Show>
  );
    


export const PrivilegesList = props => (
    <List {...props}>
      <Datagrid >
        <TextField source="id" />      
        <TextField source="code" />
        <TextField source="name" />      
      </Datagrid>
    </List>
  );
  