import React from "react";
import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  ReferenceField,
  EditButton,
  Edit,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  Create,
  BooleanInput,
  DeleteButton
} from "react-admin";

export const ContactsInOrganizationList = props => (
  <List bulkActionButtons={false} pagination={null} {...props}>
    <Datagrid>
      <ReferenceField source="userId" reference="users" linkType="show">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="label" sortable={false} />
      <BooleanField source="receiveZReports" sortable={false} />
      <EditButton />
      <DeleteButton />
    </Datagrid>
  </List>
);

export const ContactsInOrganizationEdit = props => (
  <Edit {...props}>
    <SimpleForm redirect="list">
      <TextInput source="label" />
      <BooleanInput source="receiveZReports" />
    </SimpleForm>
  </Edit>
);

export const ContactsInOrganizationCreate = props => (
  <Create {...props}>
    <SimpleForm redirect="list">
      <ReferenceInput source="userId" reference="users">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput source="label" />
      <BooleanInput source="receiveZReports" />
    </SimpleForm>
  </Create>
);
