import React from "react";
import { Admin, Resource } from "react-admin";
import { UserList, UserEdit, UserCreate, UserShow } from "./Resources/users";
import {
  OrganizationList,
  OrganizationEdit,
  OrganizationCreate,
  OrganizationShow,
  OrganizationBillingDataEdit
} from "./Resources/organizations";
import {
  BillingList,
  BillingEdit,
  BillingCreate,
  BillingShow
} from "./Resources/billings";
import {
  AgreementCreate,
  AgreementEdit
} from "./Resources/agreement";
import {
  MemberList,
  MemberEdit,
  MemberCreate,
  MemberShow
} from "./Resources/organizationMembers";
import {
  ContactsInOrganizationList,
  ContactsInOrganizationEdit,
  ContactsInOrganizationCreate
} from "./Resources/contactsInOrganization";
import {
  ContactsInEventList,
  ContactsInEventEdit,
  ContactsInEventCreate
} from "./Resources/contactsInEvent";
import {
  ContactsInGroupList,
  ContactsInGroupEdit,
  ContactsInGroupCreate
} from "./Resources/contactsInGroup";
import {
  ContactsInUserList,
  ContactsInUserEdit
} from "./Resources/contactsInUser";
import { GroupShow } from "./Resources/groups";
import { EventShow } from "./Resources/events";
import { RolesList,
  RoleShow,
  PrivilegesList
} from "./Resources/privileges";

import UserIcon from "@material-ui/icons/Group";
import OrganizationIcon from "@material-ui/icons/AccountBalance";

import CustomLayout from "./Layout/CustomLayout";
import MainDashboard from "./Dashboards/MainDashboard";

import authProvider from "./Providers/authProvider";
import dataProvider from "./Providers/dataProvider";

import englishMessages from "ra-language-english";
import swedishMessages from "ra-language-swedish";
import * as domainMessages from "./i18n";
import { PointOfSaleShow, PointOfSaleEdit } from "./Resources/pointofsale";

// BEGIN i18n

const messages = {
  sv: { ...swedishMessages, ...domainMessages.sv },
  en: { ...englishMessages, ...domainMessages.en }
};

const i18nProvider = locale => messages[locale];

// END i18n

const App = () => (
  <Admin
    locale="sv"
    i18nProvider={i18nProvider}
    dashboard={MainDashboard}
    dataProvider={dataProvider}
    authProvider={authProvider}
    title="TeamTools AdminUI"
    appLayout={CustomLayout}
  >
    <Resource
      name="users"
      list={UserList}
      edit={UserEdit}
      create={UserCreate}
      show={UserShow}
      icon={UserIcon}
    />
    <Resource
      name="organization"
      list={OrganizationList}
      edit={OrganizationEdit}
      create={OrganizationCreate}
      show={OrganizationShow}
      icon={OrganizationIcon}
    />
    <Resource
      name="organizationMembers"
      list={MemberList}
      edit={MemberEdit}
      create={MemberCreate}
      show={MemberShow}
    />
    <Resource name="events" show={EventShow} />
    <Resource name="groups" show={GroupShow} />
    <Resource name="billingData" edit={AgreementEdit} />
    <Resource name="pointsOfSale" show={PointOfSaleShow} edit={PointOfSaleEdit} />
    <Resource
      name="contactsInOrganization"
      list={ContactsInOrganizationList}
      edit={ContactsInOrganizationEdit}
      create={ContactsInOrganizationCreate}
    />
    <Resource
      name="contactsInEvent"
      list={ContactsInEventList}
      edit={ContactsInEventEdit}
      create={ContactsInEventCreate}
    />
    <Resource
      name="contactsInGroup"
      list={ContactsInGroupList}
      edit={ContactsInGroupEdit}
      create={ContactsInGroupCreate}
    />
    <Resource
      name="contactsInUser"
      list={ContactsInUserList}
      edit={ContactsInUserEdit}
    />
    <Resource
      name="billing"
      list={BillingList}
      create={BillingCreate}
      show={BillingShow}
    />
    <Resource
      name="agreement"
      create={AgreementCreate}
      edit={AgreementEdit}
    />
    <Resource
      name="roles"
      list={RolesList}
      show={RoleShow}
    />
  </Admin>
);

export default App;
