import React, { Component } from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import { changeLocale as changeLocaleAction } from "react-admin";

class LocaleSwitcher extends Component {
  switchToSwedish = () => this.props.changeLocale("sv");
  switchToEnglish = () => this.props.changeLocale("en");

  render() {
    // eslint-disable-next-line
    const { changeLocale } = this.props;
    return (
      <div>
        <div>Language</div>
        <Button onClick={this.switchToEnglish}>en</Button>
        <Button onClick={this.switchToSwedish}>sv</Button>
      </div>
    );
  }
}

export default connect(
  undefined,
  { changeLocale: changeLocaleAction }
)(LocaleSwitcher);
