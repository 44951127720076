import React from "react";
import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  ReferenceField,
  EditButton,
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput,
  DeleteButton
} from "react-admin";

export const ContactsInUserList = props => (
  <List bulkActionButtons={false} pagination={null} {...props}>
    <Datagrid>
      <ReferenceField
        source="organizationId"
        reference="organization"
        linkType="show"
        allowEmpty
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        source="eventId"
        reference="events"
        linkType="show"
        allowEmpty
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        source="groupId"
        reference="groups"
        linkType="show"
        allowEmpty
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField source="label" sortable={false} />
      <BooleanField source="receiveZReports" sortable={false} />
      <EditButton />
      <DeleteButton />
    </Datagrid>
  </List>
);

export const ContactsInUserEdit = props => (
  <Edit {...props}>
    <SimpleForm redirect="list">
      <TextInput source="label" />
      <BooleanInput source="receiveZReports" />
    </SimpleForm>
  </Edit>
);
