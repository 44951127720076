module.exports = {
  en: {
    resources: {
      users: {
        name: "User |||| Users",
        fields: {
          id: "Identifier",
          name: "Name",
          role: "Role",
          emailAddress: "Mail",
          mobileNumber: "Mobile number",
          active: "Active",
          initialOrganizationId: "Intitial organization",
          locale: "Locale",
          organizations: "Organizations",
          groups: "Groups",
          relations: "Relations",
          contacts: "Contacts"
        }
      },
      organization: {
        name: "Organization |||| Organizations",
        fields: {
          id: "Identifier",
          name: "Name",
          privatePerson: "Private person",
          responsibleUserId: "Responsible",
          orgNumber: "Organization number",
          orgNumber_short: "Org. nmbr.",
          address1: "Address row 1",
          address2: "Address row 2",
          zip: "Zip code",
          city: "City",
          phone: "Phone",
          email: "Mail",
          web: "Webpage",
          registeredForVat: "Registered for VAT",
          receiptTextHead: "Receipt header",
          receiptTextFoot: "Receipt footer",
          groups: "Groups",
          paymentMethods: "Payment methods",
          pointOfSales: "Points of sale",
          organizationMembers: "Members",
          contacts: "Contacts"
        }
      },
      organizationMembers: {
        name: "Member |||| Members",
        fields: {
          id: "Identifier",
          name: "Name",
          firstName: "First name",
          lastName: "Last name",
          dateOfBirth: "Date of birth",
          relations: "Relations",
          userId: "User",
          organizationId: "Organization"
        }
      },
      events: {
        name: "Event |||| Events",
        fields: {
          id: "Identifier",
          name: "Name",
          startDate: "Start date",
          endDate: "End date",
          groupId: "Group",
          usersInGroup: "Users",
          responsibleUserId: "Responsible"
        }
      },
      groups: {
        name: "Group |||| Groups",
        fields: {
          id: "Identifier",
          name: "Name",
          description: "Description",
          parentId: "Parent group",
          responsibleUserId: "Responsible",
          usersinGroup: "Users",
          organizationMembers: "Members"
        }
      },
      contactsInOrganization: {
        name: "Contact |||| Contacts",
        fields: {
          id: "Identifier",
          userId: "User",
          label: "Role",
          receiveZReports: "Receive Z-reports"
        }
      },
      contactsInEvent: {
        name: "Contact |||| Contacts",
        fields: {
          id: "Identifier",
          userId: "User",
          label: "Role",
          receiveZReports: "Receive Z-reports"
        }
      },
      contactsInGroup: {
        name: "Contact |||| Contacts",
        fields: {
          id: "Identifier",
          userId: "User",
          label: "Role",
          receiveZReports: "Receive Z-reports"
        }
      },
      contactsInUser: {
        name: "Contact |||| Contacts",
        fields: {
          id: "Identifier",
          userId: "User",
          organizationId: "Organization",
          eventId: "Event",
          groupId: "Grupp",
          label: "Role",
          receiveZReports: "Receive Z-reports"
        }
      }
    },
    users: {
      list: {
        search: "Search"
      },
      show: {
        tabGeneral: "General",
        tabOrganizations: "Organizations",
        organization: "Organization",
        tabGroups: "Groups",
        group: "Group",
        tabRelations: "Relations",
        name: "Name",
        association: "Association",
        tabContacts: "Contacts"
      }
    },
    organization: {
      list: {
        search: "Search"
      },
      edit: {
        tabGeneral: "General",
        tabContactInformation: "Contact information"
      },
      show: {
        tabGeneral: "General",
        tabGroups: "Groups",
        groupName: "Group name",
        description: "Description",
        parentGroup: "Parent group",
        tabEvents: "Events",
        tabPaymentMethodsAndPointOfSale: "Payment methods and points of sale",
        responsibleUser: "Responsible",
        tabMembers: "Members",
        tabContacts: "Contacts"
      }
    },
    organizationMembers: {
      list: {
        search: "Search",
        organization: "Organization",
        title: "Members in the organization"
      },
      show: {
        tabGeneral: "General",
        tabRelations: "Relations",
        name: "Name",
        association: "Association"
      }
    },
    events: {
      show: {
        tabGeneral: "General",
        tabUsers: "Users",
        tabContacts: "Contacts"
      }
    },
    groups: {
      show: {
        tabGeneral: "General",
        tabUsers: "General",
        tabMembers: "Members",
        tabContacts: "Contacts"
      }
    }
  },

  sv: {
    resources: {
      users: {
        name: "Användare |||| Användare",
        fields: {
          id: "Identifikator",
          name: "Namn",
          role: "Roll",
          emailAddress: "Mail",
          mobileNumber: "Mobilnummer",
          active: "Aktiv",
          initialOrganizationId: "Ursprunglig förening",
          locale: "Språk",
          organizations: "Föreningar",
          groups: "Grupper",
          relations: "Relationer",
          contacts: "Kontakter"
        }
      },
      organization: {
        name: "Förening |||| Föreningar",
        fields: {
          id: "Identifikator",
          name: "Namn",
          privatePerson: "Privatperson",
          responsibleUserId: "Ansvarig",
          orgNumber: "Organisationsnummer",
          orgNumber_short: "Org. nr.",
          address1: "Adressrad 1",
          address2: "Adressrad 2",
          zip: "Postnummer",
          city: "Ort",
          phone: "Telefon",
          email: "Mail",
          web: "Hemsida",
          registeredForVat: "Registrerad för VAT",
          receiptTextHead: "Kvittotext - huvud",
          receiptTextFoot: "Kvittotext - fot",
          groups: "Grupper",
          paymentMethods: "Betalningsmetoder",
          pointOfSales: "Kassor",
          organizationMembers: "Medlemmar",
          contacts: "Kontaktpersoner"
        }
      },
      organizationMembers: {
        name: "Medlem |||| Medlemmar",
        fields: {
          id: "Identifikator",
          name: "Namn",
          firstName: "Förnamn",
          lastName: "Efternamn",
          dateOfBirth: "Födelsedatum",
          relations: "Relationer",
          userId: "Användare",
          organizationId: "Förening"
        }
      },
      events: {
        name: "Evenemang |||| Evenemang",
        fields: {
          id: "Identifikator",
          name: "Namn",
          startDate: "Startdatum",
          endDate: "Slutdatum",
          groupId: "Grupp",
          usersInGroup: "Användare",
          responsibleUserId: "Ansvarig"
        }
      },
      groups: {
        name: "Grupp |||| Grupper",
        fields: {
          id: "Identifikator",
          name: "Namn",
          description: "Beskrivning",
          parentId: "Övergrupp",
          responsibleUserId: "Ansvarig",
          usersinGroup: "Användare",
          organizationMembers: "Medlemmar"
        }
      },
      contactsInOrganization: {
        name: "Kontakt |||| Kontakter",
        fields: {
          id: "Identifikator",
          userId: "Användare",
          label: "Roll",
          receiveZReports: "Z-rapportsmottagare"
        }
      },
      contactsInEvent: {
        name: "Kontakt |||| Kontakter",
        fields: {
          id: "Identifikator",
          userId: "Användare",
          label: "Roll",
          receiveZReports: "Z-rapportsmottagare"
        }
      },
      contactsInGroup: {
        name: "Kontakt |||| Kontakter",
        fields: {
          id: "Identifikator",
          userId: "Användare",
          label: "Roll",
          receiveZReports: "Z-rapportsmottagare"
        }
      },
      contactsInUser: {
        name: "Kontakt |||| Kontakter",
        fields: {
          id: "Identifikator",
          userId: "Användare",
          groupId: "Grupp",
          organizationId: "Förening",
          eventId: "Evenemang",
          label: "Roll",
          receiveZReports: "Z-rapportsmottagare"
        }
      }
    },
    users: {
      list: {
        search: "Sök"
      },
      show: {
        tabGeneral: "Sammanställning",
        tabOrganizations: "Föreningar",
        organization: "Förening",
        tabGroups: "Grupper",
        group: "Grupp",
        tabRelations: "Relationer",
        name: "Namn",
        association: "Association",
        tabContacts: "Kontakter"
      }
    },
    organization: {
      list: {
        search: "Sök"
      },
      edit: {
        tabGeneral: "Sammanställning",
        tabContactInformation: "Kontaktinformation"
      },
      show: {
        tabGeneral: "Sammanställning",
        tabGroups: "Grupper",
        groupName: "Gruppnamn",
        description: "Beskrivning",
        parentGroup: "Övergrupp",
        tabEvents: "Evenemang",
        tabPaymentMethodsAndPointOfSale: "Kassor",
        responsibleUser: "Ansvarig",
        tabMembers: "Medlemmar",
        tabContacts: "Kontakter",
        tabAgreements: "Avtal"
      }
    },
    organizationMembers: {
      list: {
        search: "Sök",
        organization: "Förening",
        title: "Medlemmar i föreningen"
      },
      show: {
        tabGeneral: "Sammanställning",
        tabRelations: "Relationer",
        name: "Namn",
        association: "Association"
      }
    },
    events: {
      show: {
        tabGeneral: "Sammanställning",
        tabUsers: "Användare",
        tabContacts: "Kontakter"
      }
    },
    groups: {
      show: {
        tabGeneral: "Sammanställning",
        tabUsers: "Användare",
        tabMembers: "Medlemmar",
        tabContacts: "Kontakter"
      }
    }
  }
};
