import {
  GET_LIST,
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  CREATE,
  UPDATE,
  DELETE,
  fetchUtils
} from "react-admin";
import { stringify } from "query-string";
import { DELETE_MANY } from "ra-core";
var constants = require("../moduleConstants");

const API_URL = constants.API_URL;

function workingOrganizationId() {
  return localStorage.getItem("workingOrganizationId");
}

function workingEventId() {
  return localStorage.getItem("workingEventId");
}

function workingGroupId() {
  return localStorage.getItem("workingGroupId");
}

function workingUserId() {
  return localStorage.getItem("workingUserId");
}

function currentAuthorizationToken() {
  return localStorage.getItem("AUTHORIZATION_TOKEN");
}

/**
 * @param {String} type One of the constants appearing at the top of this file, e.g. 'UPDATE'
 * @param {String} resource Name of the resource to fetch, e.g. 'organizations'
 * @param {Object} params The Data Provider request params, depending on the type
 * @returns {Object} { url, options } The HTTP request parameters
 */
const convertDataProviderRequestToHTTP = (type, resource, params) => {
  const options = {
    headers: new Headers({
      Authorization: currentAuthorizationToken()
    })
  };

  var organizationId = null;
  var eventId = null;
  var groupId = null;
  var userId = null;

  switch (type) {
    case GET_LIST: {
      var { q } = params.filter;
      const { field, order } = params.sort;
      const { page, perPage } = params.pagination;
      if (q === undefined) q = ""; // If query is empty, make it empty

      switch (resource) {
        case "users":
        case "organization":
          return {
            url: `${API_URL}/${resource}/list?page=${page}&pageSize=${perPage}&sortBy=${field}&order=${order}&query=${q}`,
            options: options
          };

        case "billing":
          return {
            url: `${API_URL}/${resource}/all`,
            options: options
          };

        case "agreement":
          return {
            url: `${API_URL}/billingAgreement`,
            options: options
          }

        case "organizationMembers":
          organizationId = workingOrganizationId();
          return {
            url: `${API_URL}/organization/${organizationId}/${resource}/list?page=${page}&pageSize=${perPage}&sortBy=${field}&order=${order}&query=${q}`,
            options: options
          };

        case "pointsOfSale":
          organizationId = workingOrganizationId();
          return {
            url: `${API_URL}/pointofsale/${organizationId}/FindAll`,
            options: options
          };

        case "contactsInOrganization":
          organizationId = workingOrganizationId();
          return {
            url: `${API_URL}/contacts/findByOrganization/${organizationId}`,
            options: options
          };

        case "contactsInEvent":
          eventId = workingEventId();
          return {
            url: `${API_URL}/contacts/findByEvent/${eventId}`,
            options: options
          };

        case "contactsInGroup":
          groupId = workingGroupId();
          return {
            url: `${API_URL}/contacts/findByGroup/${groupId}`,
            options: options
          };

        case "contactsInUser":
          userId = workingUserId();
          return {
            url: `${API_URL}/contacts/findByUser/${userId}`,
            options: options
          };

        default:
          const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify(params.filter)
          };
          return {
            url: `${API_URL}/${resource}?${stringify(query)}`,
            options: options
          };
      }
    }

    case GET_ONE:
      switch (resource) {
        case "agreement":
          return {
            url: `${API_URL}/billingAgreement/${params.id}`,
            options: options
          };
        case "billingData":
          organizationId = workingOrganizationId();
          return {
            url: `${API_URL}/organization/${organizationId}/billingData/${params.id}`,
            options: options
          }
        case "contactsInOrganization":
        case "contactsInEvent":
        case "contactsInGroup":
        case "contactsInUser":
          return { url: `${API_URL}/contacts/${params.id}`, options: options };

        case "groups":
          organizationId = workingOrganizationId();
          return {
            url: `${API_URL}/organization/${organizationId}/groups/${
              params.id
            }`,
            options: options
          };

        case "events":
          organizationId = workingOrganizationId();
          return {
            url: `${API_URL}/organization/${organizationId}/events/${
              params.id
            }`,
            options: options
          };

        case "pointsOfSale":
          organizationId = workingOrganizationId();
          return {
            url: `${API_URL}/organization/${organizationId}/pointofsale/${
              params.id
            }`,
            options: options
          };
        case "organization":
              return {
                url: `${API_URL}/organizationadmin/${params.id}`,
                options: options
              };

        default:
          return {
            url: `${API_URL}/${resource}/${params.id}`,
            options: options
          };
      }

    case GET_MANY: {
      const query = {
        filter: JSON.stringify({ id: params.ids })
      };

      switch (resource) {
        case "agreement":
          return {
            url: `${API_URL}/billingAgreements/${params.ids}`,
            options: options
          };
        case "events":
          organizationId = workingOrganizationId();
          return {
            url: `${API_URL}/organization/${organizationId}/events?${stringify(
              query
            )}`,
            options: options
          };
        
          case "pointofsale":
            organizationId = workingOrganizationId();
            return {
              url: `${API_URL}/organization/${organizationId}/pointofsale?${stringify(
                query
              )}`,
              options: options
            };
        default:
          return {
            url: `${API_URL}/${resource}?${stringify(query)}`,
            options: options
          };
      }
    }

    case GET_MANY_REFERENCE: {
      switch (resource) {
        default:
          const { page, perPage } = params.pagination;
          const { field, order } = params.sort;
          const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify({
              ...params.filter,
              [params.target]: params.id
            })
          };
          return {
            url: `${API_URL}/${resource}?${stringify(query)}`,
            options: options
          };
      }
    }

    case UPDATE:
      switch (resource) {
          

        case "organization":
          params.data.paymentMethods = null; // Empty payment methods before updating
          return {
            url: `${API_URL}/${resource}/${params.id}`,
            options: {
              method: "POST",
              body: JSON.stringify(params.data),
              ...options
            }
          };

        case "billingData":
        case "organizationMembers":
          organizationId = workingOrganizationId();
          return {
            url: `${API_URL}/organization/${organizationId}/${resource}/${
              params.id
            }`,
            options: {
              method: "POST",
              body: JSON.stringify(params.data),
              ...options
            }
          };
          
          case "pointsOfSale":
          case "pointOfSale":
              organizationId = workingOrganizationId();
            return {
              url: `${API_URL}/organization/${organizationId}/pointofsale/${
                params.id
              }`,
              options: {
                method: "POST",
                body: JSON.stringify(params.data),
                ...options
              }
            };
  
          case "runBilling":
              return {
                url: `${API_URL}/billing/${params.id}/run`,
                options: {
                  method: "GET",
                  ...options
                }
              };

        case "contactsInOrganization":
        case "contactsInEvent":
        case "contactsInGroup":
        case "contactsInUser":
          return {
            url: `${API_URL}/contacts/save/`,
            options: {
              method: "POST",
              body: JSON.stringify(params.data),
              ...options
            }
          };

        default:
          return {
            url: `${API_URL}/${resource}/${params.id}`,
            options: {
              method: "POST",
              body: JSON.stringify(params.data),
              ...options
            }
          };
      }
    case CREATE:
      switch (resource) {
        case "users":
        case "organization":
          return {
            url: `${API_URL}/${resource}/create`,
            options: {
              method: "POST",
              body: JSON.stringify(params.data),
              ...options
            }
          };

        case "organizationMembers":
          organizationId = workingOrganizationId();
          params.data.organizationId = organizationId;
          return {
            url: `${API_URL}/organization/${organizationId}/${resource}`,
            options: {
              method: "POST",
              body: JSON.stringify(params.data),
              ...options
            }
          };

        case "contactsInOrganization":
          organizationId = workingOrganizationId();
          params.data.organizationId = organizationId;
          return {
            url: `${API_URL}/contacts/save/`,
            options: {
              method: "POST",
              body: JSON.stringify(params.data),
              ...options
            }
          };

        case "contactsInEvent":
          eventId = workingEventId();
          params.data.eventId = eventId;
          return {
            url: `${API_URL}/contacts/save/`,
            options: {
              method: "POST",
              body: JSON.stringify(params.data),
              ...options
            }
          };

        case "contactsInGroup":
          groupId = workingGroupId();
          params.data.groupId = groupId;
          return {
            url: `${API_URL}/contacts/save/`,
            options: {
              method: "POST",
              body: JSON.stringify(params.data),
              ...options
            }
          };

        case "agreement":
          return {
            url: `${API_URL}/organization/${params.data.organizationId}/billingData`,
            options: {
              method: "POST",
              body: JSON.stringify(params.data),
              ...options
            }
          };


        default:
          return {
            url: `${API_URL}/${resource}`,
            options: {
              method: "POST",
              body: JSON.stringify(params.data),
              ...options
            }
          };
      }
    case DELETE:
      switch (resource) {
        case "organizationMembers":
        case "billingData":

          organizationId = workingOrganizationId();
          return {
            url: `${API_URL}/organization/${organizationId}/${resource}/${
              params.id
            }`,
            options: {
              method: "DELETE",
              ...options
            }
          };

        case "contactsInOrganization":
        case "contactsInEvent":
        case "contactsInGroup":
        case "contactsInUser":
          return {
            url: `${API_URL}/contacts/${params.id}`,
            options: {
              method: "DELETE",
              ...options
            }
          };

        default:
          return {
            url: `${API_URL}/${resource}/${params.id}`,
            options: {
              method: "DELETE",
              ...options
            }
          };
      }
   case DELETE_MANY:
    switch (resource) {
      default:
        return {
          url: `${API_URL}/${resource}/${params.ids}`,
          options: {
            method: "DELETE",
            ...options
          }
        };
    }

    default:
      throw new Error(`Unsupported fetch action type ${type}`);
  }
};

/**
 * @param {Object} response HTTP response from fetch()
 * @param {String} type One of the constants appearing at the top of this file, e.g. 'UPDATE'
 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
 * @param {Object} params The Data Provider request params, depending on the type
 * @returns {Object} Data Provider response
 */
const convertHTTPResponseToDataProvider = (
  response,
  type,
  resource,
  params
) => {
  const { json } = response;
  console.log(response);
  switch (type) {
    case GET_LIST:
      return {
        data: json.data.map(x => x),
        // TODO: Fix total amount of entries in database (via Solr implementation)
        total: json.itemCount
      };
    case GET_ONE:
      switch (resource) {
        case "groups": // Add case for handeling GroupExtended
          var data = json.data;
          data = { ...data, ...data.group };
          console.log("Actual GET_ONE data for groups");
          console.log(data);
          return { data: data };

        default:
          return { data: json.data };
      }

    case CREATE:
      return { data: { ...params.data, id: json.id } };
    default:
      return { data: json.data };
  }
};

/**
 * @param {string} type Request type, e.g GET_LIST
 * @param {string} resource Resource name, e.g. "posts"
 * @param {Object} payload Request parameters. Depends on the request type
 * @returns {Promise} the Promise for response
 */
export default (type, resource, params) => {
  const { fetchJson } = fetchUtils;
  const { url, options } = convertDataProviderRequestToHTTP(
    type,
    resource,
    params
  );
  console.log("!------------------------!");
  console.log("Request type: " + type);
  console.log("Request resource: " + resource);
  console.log("Request URL: " + url);
  console.log("Request parameters & options");
  console.log({ params, options });
  console.log("!------------------------!");

  return fetchJson(url, options).then(response =>
    convertHTTPResponseToDataProvider(response, type, resource, params)
  );
};
