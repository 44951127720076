import React from "react";
import {
  Button,
  List,
  Link,
  Datagrid,
  TextField,
  ReferenceField,
  BooleanField,
  EditButton,
  EmailField,
  Edit,
  SimpleForm,
  TextInput,
  UrlField,
  BooleanInput,
  ReferenceInput,
  SelectInput,
  DisabledInput,
  DateInput,
  Create,
  Filter,
  Show,
  TabbedShowLayout,
  Tab,
  NumberField,
  ArrayField,
  DateField,
  TabbedForm,
  FormTab
} from "react-admin";
import { Hidden } from "@material-ui/core";

var constants = require("../moduleConstants");
const ADMINUI_URL = constants.ADMINUI_URL;

const setCurrentOrganization = ({ record }) => {
  localStorage.setItem("workingOrganizationId", record.id);
  localStorage.setItem("workingOrganizationName", record.name);
};

const OrganizationMembersViewLink = ({ record }) => {
  setCurrentOrganization({ record });
  var link = `${ADMINUI_URL}/#/organizationMembers?filter=%7B%22organizationId%22%3A%22${
    record.id
  }%22%7D&order=DESC&page=1&perPage=10&sort=id`;
  return (
    <p>
      För att lägga till, ändra eller ta bort medlemmar, klicka{" "}
      <a href={link}>här</a>
    </p>
  );
};

const ContactsViewLink = ({ record }) => {
  setCurrentOrganization({ record });
  var link = `${ADMINUI_URL}/#/contactsInOrganization?filter=%7B%22organizationId%22%3A%22${
    record.id
  }%22%7D`;
  return (
    <p>
      För att lägga till, ändra eller ta bort kontakter, klicka{" "}
      <a href={link}>här</a>
    </p>
  );
};

const OrganizationTitle = ({ record }) => {
  return <span>Förening {record ? `"${record.name}"` : ""}</span>;
};

const OrganizationFilter = props => (
  <Filter {...props}>
    <TextInput label="organization.list.search" source="q" alwaysOn />
  </Filter>
);

const organizationRowStyle = (record, index) => ({
  backgroundColor: record.privatePerson === true ? "#efe" : "white"
});

export const OrganizationList = props => (
  <List filters={<OrganizationFilter />} {...props}>
    <Datagrid rowClick="show" rowStyle={organizationRowStyle}>
      <TextField source="name" />
      <BooleanField source="privatePerson" />
      <ReferenceField
        source="responsibleUserId"
        reference="users"
        linkType="show"
        allowEmpty
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField source="orgNumber" sortBy="searchTerm2" />
      <TextField source="address1" />
      <TextField source="address2" />
      <TextField source="zip" />
      <TextField source="city" sortBy="searchTerm1" />
      <TextField source="phone" />
      <EmailField source="email" />
      <TextField source="web" />
      <EditButton />
    </Datagrid>
  </List>
);

export const OrganizationEdit = props => (
  <Edit title={<OrganizationTitle />} {...props}>
    <TabbedForm>
      <FormTab label="organization.edit.tabGeneral">
        <DisabledInput source="id" />
        <TextInput source="name" />
        <BooleanInput source="privatePerson" />
        <ReferenceInput source="responsibleUserId" reference="users">
          <SelectInput optionText="name" />
        </ReferenceInput>
        <TextInput source="orgNumber" />

        <BooleanInput source="registeredForVat" />
        <TextInput source="receiptTextHead" />
        <TextInput source="receiptTextFoot" />
      </FormTab>
      <FormTab label="organization.edit.tabContactInformation">
        <TextInput source="address1" />
        <TextInput source="address2" />
        <TextInput source="zip" />
        <TextInput source="city" />
        <TextInput source="phone" />
        <TextInput source="email" />
        <TextInput source="web" />
      </FormTab>
    </TabbedForm>
  </Edit>
);

export const OrganizationBillingDataEdit = props => (
  <Edit title={<OrganizationTitle />} {...props}>
    <TabbedForm>
      <FormTab label="organization.edit.tabGeneral">
        <DisabledInput source="id" />
        <DateInput source="startDate" />
        <DateInput source="endDate" />
        <TextInput source="agreementId" />
        
      </FormTab> 
    </TabbedForm>
  </Edit>
);

export const OrganizationCreate = props => (
  <Create title="Skapa förening" {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <BooleanInput source="privatePerson" />
      <ReferenceInput source="responsibleUserId" reference="users">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput source="orgNumber" />
      <TextInput source="address1" />
      <TextInput source="address2" />
      <TextInput source="zip" />
      <TextInput source="city" />
      <TextInput source="phone" />
      <TextInput source="email" />
      <TextInput source="web" />
      <BooleanInput source="registeredForVat" />
      <TextInput source="receiptTextHead" />
      <TextInput source="receiptTextFoot" />
    </SimpleForm>
  </Create>
);

export const OrganizationShow = props => {

  return(
  <Show title={<OrganizationTitle />} {...props}>
    <TabbedShowLayout>
      <Tab label="organization.show.tabGeneral">
        <TextField source="id" />
        <TextField source="name" />
        <BooleanField source="privatePerson" />
        <ReferenceField
          source="responsibleUserId"
          reference="users"
          linkType="show"
          allowEmpty
        >
          <TextField source="name" />
        </ReferenceField>
        <NumberField source="orgNumber" />
        <TextField source="address1" />
        <TextField source="address2" />
        <NumberField source="zip" />
        <TextField source="city" />
        <NumberField source="phone" />
        <EmailField source="email" />
        <TextField source="web" />
        <BooleanField source="registeredForVat" />
        <TextField source="receiptTextHead" />
        <TextField source="receiptTextFoot" />
      </Tab>
      <Tab label="organization.show.tabGroups">
        <ArrayField source="groups">
          <Datagrid>
            <ReferenceField
              label="organization.show.groupName"
              source="id"
              reference="groups"
              linkType="show"
            >
              <TextField source="name" />
            </ReferenceField>
            <ReferenceField
              label="organization.show.responsibleUser"
              source="responsibleUserId"
              reference="users"
              linkType="show"
              allowEmpty
            >
              <TextField source="name" />
            </ReferenceField>
            <ReferenceField
              label="organization.show.parentGroup"
              source="parentId"
              reference="groups"
              linkType="show"
              allowEmpty
            >
              <TextField source="name" />
            </ReferenceField>
            <TextField
              source="description"
              label="organization.show.description"
            />
          </Datagrid>
        </ArrayField>
      </Tab>
      <Tab label="organization.show.tabEvents">
        <ArrayField source="events">
          <Datagrid>
            <ReferenceField
              label="organization.show.eventName"
              source="id"
              reference="events"
              linkType="show"
            >
              <TextField source="name" />
            </ReferenceField>
            <DateField source="startDate" />
            <DateField source="endDate" />
            <ReferenceField
              source="groupId"
              reference="groups"
              linkType="show"
              allowEmpty
            >
              <TextField source="name" />
            </ReferenceField>
          </Datagrid>
        </ArrayField>
      </Tab>
      <Tab label="organization.show.tabPaymentMethodsAndPointOfSale">
        <ArrayField source="paymentMethods">
          <Datagrid>
            <TextField source="method" />
            <TextField source="recipient" />
            <TextField source="description" />
            <BooleanField source="active" />
            <BooleanField source="locked" />
          </Datagrid>
        </ArrayField>
        <ArrayField source="pointOfSales">
          <Datagrid>
            <TextField source="name" />
            <BooleanField source="active" />
            <BooleanField source="inheritPrivileges" />
            <BooleanField source="excludeFromBilling" />
            <DateField source="startDate" />
            <DateField source="endDate" />
            <EditPosButton />
          </Datagrid>
        </ArrayField>
        <ArrayField source="zReports">
          <Datagrid >
            <DateField source="date" />
            <TextField source="posName" />
            <NumberField source="numberOfReciepts" />
            <NumberField source="totalSales" />
            <NumberField source="comment" />
            <UrlField source="reportUrl" target="_top" />
          </Datagrid>
        </ArrayField>
      </Tab>
      <Tab label="organization.show.tabMembers">
        <OrganizationMembersViewLink />

        <ArrayField source="organizationMembers">
          <Datagrid>
            <ReferenceField
              label="users.show.name"
              source="id"
              reference="organizationMembers"
              linkType="show"
            >
              <TextField source="name" />
            </ReferenceField>
            <DateField source="dateOfBirth" />
          </Datagrid>
        </ArrayField>
      </Tab>
      <Tab label="organization.show.tabAgreements">
        <ArrayField source="billingData">
        <Datagrid>
          <DateField source="startDate" />
            <DateField source="endDate" />
            <DateField source="signDate" />
            <ReferenceField label="Avtal" source="agreementId" reference="agreement" linkType="none">
                <TextField source="name"  />
            </ReferenceField>
            <EditAgreementButton />
        </Datagrid>
        </ArrayField>
        <ArrayField source="billingHeads">
        <Datagrid>
          <TextField source="name" /> 
          <DateField source="startDate" />
          <DateField source="created" />
          <TextField source="invoiceNumber" />
        </Datagrid>
        </ArrayField>
        <AddAgreementButton />
      </Tab>
      <Tab label="organization.show.tabContacts">
        <ContactsViewLink />
      </Tab>
      
    </TabbedShowLayout>
  </Show>
);
  };

const EditAgreementButton = ({ record }) => {
  localStorage.setItem("workingOrganizationId", record.organizationId);
  return (
  <Button
      component={Link}
      to={`/billingData/${record.id}?organizationId=${record.organizationId}`}
      label="Edit"
  >
  </Button>
);
  }

  const EditPosButton = ({ record }) => {
    localStorage.setItem("workingOrganizationId", record.organizationId);
    return (
    <Button
        component={Link}
        to={`/pointsOfSale/${record.id}?organizationId=${record.organizationId}`}
        label="Edit"
    >
    </Button>
  );
    } 

const AddAgreementButton = ({ record }) => (
  <Button
      component={Link}
      to={`/agreement/create?organizationId=${record.id}`}
      label="Add new agreement"
  >
  </Button>
);
