import React from "react";
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  BooleanField,
  EditButton,
  EmailField,
  Edit,
  SimpleForm,
  TextInput,
  DateInput,
  BooleanInput,
  ReferenceInput,
  SelectInput,
  DisabledInput,
  Create,
  Filter,
  Show,
  TabbedShowLayout,
  Tab,
  NumberField,
  ArrayField,
  DateField,
  TabbedForm,
  FormTab,
  Mutation,
  Button
} from "react-admin";

var constants = require("../moduleConstants");
const ADMINUI_URL = constants.ADMINUI_URL;
const API_URL = constants.API_URL;

const BillingViewLink = ({ record }) => {
  setCurrentBillingRun({ record });
  var link = `${ADMINUI_URL}/#/billingRun?filter=%7B%22id%22%3A%22${
    record.id
  }%22%7D&order=DESC&page=1&perPage=10&sort=id`;
  return (
    <p>
      Kör en fakturering för att se något här
    </p>
  );
};


const BillingRunLink = ({ record }) => {
  setCurrentBillingRun({ record });
  var link = `${ADMINUI_URL}/#/billingrun?filter=%7B%22organizationId%22%3A%22${
    record.id
  }%22%7D&order=DESC&page=1&perPage=10&sort=id`;
  return (
    <p>
      Kör debiteringen genom att klicka {" "}
      <a href={link}>här</a>
    </p>
  );
};

const setCurrentBillingRun = ({ record }) => {
  localStorage.setItem("wowrkingBillingRun", record.id);
};

const BillingTitle = ({ record }) => {
  return <span>Fakturering {record ? `"${record.name}"` : ""}</span>;
};

const BillingFilter = props => (
  <Filter {...props}>
    <TextInput label="organization.list.search" source="q" alwaysOn />
  </Filter>
);

const billingRowStyle = (record, index) => ({
  backgroundColor: record.privatePerson === true ? "#efe" : "white"
});


export const BillingList = props => (
  <List filters={<BillingFilter />} {...props}>
    <Datagrid rowClick="show" rowStyle={billingRowStyle}>
      <TextField source="name" />      
      <DateField source="startDate" />
      <DateField source="endDate" />      
    </Datagrid>
  </List>
);





export const BillingEdit = props => (
  <Show title={<BillingTitle />} {...props}>
    <TabbedShowLayout>
      <Tab label="events.show.tabGeneral">
        <TextField source="id" />
        <TextField source="name" />
        <DateField source="startDate" />
        <DateField source="endDate" />
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export const BillingCreate = props => (
  <Create title={<BillingTitle />} {...props}>
    <SimpleForm>
        <TextField source="id" />
        <DateInput source="startDate" />
    </SimpleForm>
  </Create>
);


const runOptions = {
  undoable: true,
  onSuccess: {
      notification: { body: 'Billing job started', level: 'info' },
      redirectTo: '/billing',
  },
  onFailure: {
      notification: { body: 'Error: billing job failed', level: 'warning' }
  }
};

export const RunButton = ({ record }) => {
  const payload = { id: record.id, data: { ...record } };
  return (
      <Mutation
          type="UPDATE"
          resource="runBilling"
          payload={payload}
          options={runOptions}
      >
          {(approve) => (
              <Button label="Run" onClick={approve} />
          )}
      </Mutation>
  );
}

export const BillingShow = props => {
  const apiUrl = `${API_URL}/report/billing/${props.id}`
  return (
  <Show title={<BillingTitle />} {...props}>
    <TabbedShowLayout>
      <Tab label="events.show.tabGeneral">
        <TextField source="id" />
        <TextField source="name" />
        <DateField source="startDate" />
        <DateField source="endDate" />
        <RunButton />
      </Tab>
      <Tab label="Underlag">
        <TextField source="id" />
        <iframe src={apiUrl} style={{width: "100%", height: "100vh", border: "none"}}></iframe>
      </Tab>
    </TabbedShowLayout>
  </Show>
)};


